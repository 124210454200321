import {
  Box,
  Container,
  Grid,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  cashPng,
  ethIcon,
  hBg,
  lights,
  livesaleBg,
  paginationBg,
  puglivesale,
} from "../components/SmallComponents/Images";
import {
  preSaleReadFunction,
  tokenReadFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits } from "viem";
import { AppContext } from "../utils";
import moment from "moment";

const toLocalFormat = (val) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const LiveSale = () => {
  const { callFunction, setCallFunction } = useContext(AppContext);
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const [saleData, setSaleData] = useState([
    {
      time: 0,
      buyAmount: 0,
      tokenAmount: 0,
      isEther: false,
    },
    {
      time: 0,
      buyAmount: 0,
      tokenAmount: 0,
      isEther: false,
    },
    {
      time: 0,
      buyAmount: 0,
      tokenAmount: 0,
      isEther: false,
    },
    {
      time: 0,
      buyAmount: 0,
      tokenAmount: 0,
      isEther: false,
    },
  ]);

  const postsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const handleChangepage = (event, value) => {
    setCurrentPage(value);
  };
  const pageCount = Math.ceil(saleData?.length / postsPerPage);

  useEffect(() => {
    (async () => {
      try {
        const tokenDecimal = await tokenReadFunction("decimals");
        const dt = [];
        for (let i = 0; i < 20; i++) {
          try {
            const transactionContract = await preSaleReadFunction(
              "transactionsHistory",
              [i]
            );

            const obj = {
              time: +transactionContract[0]?.toString(),
              buyAmount: +parseFloat(
                formatUnits(transactionContract[2]?.toString(), 18)
              )?.toFixed(3),
              tokenAmount: +parseFloat(
                formatUnits(
                  transactionContract[3]?.toString(),
                  +tokenDecimal?.toString()
                )
              )?.toFixed(0),
              isEther: transactionContract[4],
            };
            dt.push(obj);
          } catch (e) {
            // dt.push({
            //   time: 0,
            //   buyAmount: 0,
            //   tokenAmount: 0,
            //   isEther: false,
            // });
          }
        }
        setSaleData(dt);
      } catch (err) {
        console.log(err);
      }
    })();
    setCallFunction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction]);
  return (
    <Box mt={3}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component={"img"}
          src={lights}
          sx={{
            // height: { xs: "200px", md: "200px" },
            // width: { xs: "300px", md: "1000px" },
            width: { xs: "80%", sm: "80%", md: "60%" },
            position: "relative",
            top: { xs: 23, sm: 50, md: 56, xl: 76, lg: 65 },
          }}
        />
      </Box>
      <Box
        pb={5}
        pt={{ xs: 0, sm: 4, md: 5 }}
        sx={{
          backgroundImage: `url(${livesaleBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: { xs: "140% 100%", sm: "108% 100%" },
          backgroundPosition: "center",
          height: "fit-content",
          width: { xs: "100%", md: "100%" },
          position: "relative",
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              ml: { xs: 0, md: 3 },
              textAlign: { xs: "center", md: "left" },
              display: "flex",
              justifyContent: { xs: "center", md: "left" },
              alignItems: "center",
            }}
          >
            <Box
              data-aos={"fade-down"}
              data-aos-delay="30"
              data-aos-duration="3000"
              component={"img"}
              alt=""
              src={cashPng}
              sx={{
                width: { xs: "20%", sm: "15%", md: "6%" },
                position: "absolute",
                top: "20%",
                left: { xs: "2%", sm: "2%", md: "0%" },
              }}
            />
            <Box
              data-aos={"fade-down"}
              data-aos-delay="50"
              data-aos-duration="3000"
              component={"img"}
              alt=""
              src={cashPng}
              sx={{
                width: { xs: "20%", sm: "15%", md: "6%" },
                position: "absolute",
                top: "40%",
                left: { xs: "2%", sm: "2%", md: "0%" },
              }}
            />
            <Box
              data-aos={"fade-down"}
              data-aos-delay="30"
              data-aos-duration="3000"
              component={"img"}
              alt=""
              src={cashPng}
              sx={{
                width: { xs: "20%", sm: "15%", md: "6%" },
                position: "absolute",
                top: "18%",
                left: { xs: "67%", sm: "80%", md: "49%" },
              }}
            />
            <Box
              data-aos={"fade-down"}
              data-aos-delay="50"
              data-aos-duration="3000"
              component={"img"}
              alt=""
              src={cashPng}
              sx={{
                width: { xs: "20%", sm: "15%", md: "6%" },
                position: "absolute",
                top: "40%",
                left: { xs: "70%", sm: "80%", md: "58%", xl: "50%", lg: "54%" },
                zIndex: 0,
              }}
            />
            <Box
              mt={6}
              sx={{
                color: "#ffffff",
                backgroundImage: `url(${hBg})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                py: { xs: 2, md: 4 },
                px: 5,
                textAlign: "center",
                height: "fit-content",
                width: "fit-content",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Wagoon Bold",
                  fontSize: { xs: "24px", md: "48px" },
                  fontWeight: "400",
                  lineHeight: "48px",
                }}
              >
                Live Sale
              </Typography>
            </Box>
          </Box>
          <Typography
            pt={3}
            sx={{
              ml: { xs: 0, md: 6 },
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0em",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Keep track of the latest buys of BLAST!
          </Typography>
          <Grid
            container
            spacing={4}
            flexDirection={{
              xs: "column-reverse",
              md: "row",
            }}
            alignItems="center"
          >
            <Grid item xs={12} md={7}>
              {saleData
                ?.slice(
                  currentPage * postsPerPage - postsPerPage,
                  currentPage * postsPerPage
                )
                ?.map(({ time, buyAmount, tokenAmount, isEther }, index) => (
                  <Box
                    data-aos={mobileMatches ? "fade-up" : "fade-right"}
                    data-aos-delay="30"
                    data-aos-duration="2000"
                    pt={5}
                    key={index}
                    sx={{
                      pl: { xs: 0, md: 5 },
                      display: "flex",
                      justifyContent: { xs: "center", md: "left" },
                    }}
                  >
                    <Box
                      zIndex={1}
                      sx={{
                        width: { xs: "100%", md: "75%" },
                        height: "90px",
                        background:
                          "linear-gradient(180deg, #FED319 -26.27%, #EE7D08 133.33%)",
                        borderRadius: "12px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Box ml={4}>
                        <Box
                          component={"img"}
                          src={ethIcon}
                          sx={{ height: "40px", width: "40px" }}
                        />
                      </Box>
                      <Stack ml={2} direction={"column"} flexGrow={1}>
                        <Typography
                          sx={{
                            fontFamily: "Wagoon Bold",
                            fontSize: { xs: "20px", sm: "24px" },
                            fontWeight: 400,
                            lineHeight: "30px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "#ffffff",
                          }}
                        >
                          FLR
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: { xs: "12px", sm: "15px" },
                            fontWeight: 600,
                            lineHeight: "30px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "#ffffff",
                          }}
                        >
                          FLR {buyAmount} ({toLocalFormat(+tokenAmount)} $BLAST)
                        </Typography>
                      </Stack>
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"flex-end"}
                        // sx={{ width: "100%" }}
                        px={5}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: { xs: "14px", sm: "17px" },
                            fontWeight: 700,
                            lineHeight: "46px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "#ffffff",
                          }}
                        >
                          {moment.unix(time).format("HH:mm")}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                ))}
              {saleData?.length > 4 && (
                <Box mt={2}>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: { xs: "100%", md: "75%" },
                    }}
                  >
                    <Pagination
                      sx={{
                        ".MuiButtonBase-root": {
                          fontFamily: "Montserrat",
                          fontSize: "20px",
                          fontWeight: 500,
                        },
                        ".Mui-selected": {
                          backgroundImage: `url(${paginationBg})`,
                          backgroundSize: "119% 100%",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "cover",
                          backgroundColor: "transparent",
                          color: "#ffffff",
                          fontWeight: 600,
                        },
                      }}
                      count={pageCount}
                      page={currentPage}
                      onChange={handleChangepage}
                    />
                  </Stack>
                </Box>
              )}
            </Grid>

            <Grid
              item
              mt={5}
              xs={12}
              md={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: { xs: "center", md: "end" },
                overflow: "hidden",
              }}
            >
              <Box>
                <Box
                  data-aos={mobileMatches ? "fade-down" : "fade-left"}
                  data-aos-delay="30"
                  data-aos-duration="2000"
                  component="img"
                  src={puglivesale}
                  sx={{
                    width: {
                      xs: "100%",
                      md: saleData?.length > 4 ? "590px" : "560px",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default LiveSale;

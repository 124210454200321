import React, { useContext, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import Header from "../components/Header";
import {
  heroSectionBg1,
  preSaleBoxGrass,
  refBg,
} from "../components/SmallComponents/Images";
import {
  StyledButton,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import PresaleBox from "../components/SmallComponents/PresaleBox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AppContext } from "../utils";

function HeroSection() {
  const { account } = useContext(AppContext);
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const matchesTwo = useMediaQuery("(max-width:1050px)");
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Box
        id="home"
        sx={{
          background: "#F7AC11",
          pb: 3,
          backgroundImage: `url(${preSaleBoxGrass})`,
          backgroundSize: "300px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom right 50px",
        }}
      >
        <Header />

        <Container maxWidth="xl">
          <Grid
            container
            my={{ xs: 2, md: 0 }}
            rowSpacing={{ xs: 2, md: 4 }}
            justifyContent={"center"}
            flexDirection={{
              xs: "column-reverse",
              md: matchesTwo ? "column-reverse" : "row",
            }}
          >
            <Grid
              item
              xs={12}
              sm={10}
              md={matchesTwo ? 8 : 5}
              sx={{
                margin: { xs: "auto", md: matchesTwo ? "auto" : "inherit" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                data-aos={mobileMatches ? "fade-up" : "fade-right"}
                data-aos-delay="30"
                data-aos-duration="2000"
                // mt={{ xs: 0, md: 4 }}
                sx={{
                  height: { xs: "70%", md: matchesTwo ? "70%" : "100%" },
                  backgroundImage: `url(${heroSectionBg1})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  px: { xs: 8, sm: 10, md: matchesTwo ? 13 : 10 },
                  pt: { xs: 10, md: matchesTwo ? 10 : 0 },
                  pb: { xs: 8, md: matchesTwo ? 8 : 0 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Wagoon Bold",
                      fontSize: {
                        xs: "23px",
                        sm: "30px",
                        md: "35px",
                        lg: "35px",
                        xl: "40px",
                      },
                      fontWeight: "400",
                      lineHeight: { xs: "32px", sm: "48px" },
                      color: "#D89F00",
                      textShadow: "2px 2px 4px #F6C089",
                    }}
                  >
                    NOTICED FLARE BEING TOP 100 WITH ENORMOUS POTENTIAL FOR ITS
                    OWN EXPONENTIAL MEMECOIN GROWTH? <br />
                    $BLAST IS HERE!
                  </Typography>
                  <Typography
                    variant="h5"
                    my={2}
                    sx={{
                      color: "#818181",
                      fontFamily: "Montserrat",
                      fontSize: { xs: "10px", sm: "12px" },
                      fontWeight: "400",
                      lineHeight: {
                        xs: "13px",
                        md: matchesTwo ? "13px" : "18px",
                      },
                    }}
                  >
                    Memecoin season is only just starting on Flare and there is
                    a huge opportunity for growth in this space. $BLAST combines
                    the winning ingredients to be the number 1 on the network.
                    It’s a funny dog heading in one direction only, backed by a
                    network of influencers and respected community members. This
                    canine wants to dominate the exchanges, social media and
                    crypto press!
                  </Typography>
                  <Box
                    mt={{ xs: 2, md: 3 }}
                    mb={{ xs: 2, md: 2 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent="center"
                    flexDirection={{ xs: "column", sm: "row" }}
                    flex
                    gap={2}
                  >
                    <StyledButton
                      href="/whitepaper.pdf"
                      target="_blank"
                      btnStatus={true}
                      width={{ xs: "180px", sm: "150px" }}
                    >
                      Whitepaper
                    </StyledButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* {matches ? null : ( */}
            <Grid
              item
              xs={12}
              sm={12}
              md={matchesTwo ? 10 : 6.5}
              sx={{
                margin: { xs: "inherit", md: matchesTwo ? "auto" : "inherit" },
              }}
            >
              <PresaleBox />
            </Grid>
            {/* )} */}
          </Grid>
        </Container>
        <Container maxWidth="md">
          <Box
            sx={{
              backgroundImage: `url(${refBg})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",

              p: matchesTwo ? 1 : 2.5,
              // py: 1,
              borderRadius: "20px",
              mt: 3,
            }}
          >
            <Box p={2} borderRadius="15px" bgcolor="#E3C19F">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Wagoon Bold",
                  fontSize: { xs: "20px", sm: "26px" },
                  fontWeight: "400",
                  color: "#D89F00",
                }}
              >
                EARN DIRECTLY FROM REFERRALS
              </Typography>
              <Typography
                variant="h5"
                my={1}
                sx={{
                  color: "#818181",
                  fontFamily: "Montserrat",
                  fontSize: { xs: "13px", sm: "15px" },
                  fontWeight: "500",
                  lineHeight: {
                    xs: "16px",
                    md: matchesTwo ? "16px" : "20px",
                  },
                }}
              >
                Receive 10% directly of all purchases made using your unique
                link
              </Typography>
              <Typography
                variant="h5"
                mt={2}
                sx={{
                  color: "#818181",
                  fontFamily: "Montserrat",
                  fontSize: { xs: "14px", sm: "15px" },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "13px",
                    md: matchesTwo ? "13px" : "18px",
                  },
                }}
              >
                Your Referral Link
              </Typography>
              <Box
                sx={{
                  boxShadow:
                    "0px 3.4595067501068115px 21.621915817260742px 0px #0000004D",
                  background: "#ffffff",
                  border: "0.43px solid #505050",
                  borderRadius: "7px",
                  my: 1,
                  py: 0.5,
                  px: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily: "Montserrat",
                    color: "#505050",
                    fontWeight: "500",
                  }}
                >
                  {account
                    ? window?.location?.origin + "/?ref=" + account
                    : "Connect your wallet and get your unique referral link here"}
                </Typography>
                {account ? (
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(
                        window?.location?.origin + "/?ref=" + account
                      );
                      setAlertState({
                        open: true,
                        message: "Success! Referral link copied",
                        severity: "success",
                      });
                    }}
                  >
                    <ContentCopyIcon sx={{ color: "#505050" }} />
                  </IconButton>
                ) : null}
              </Box>
              <Typography
                variant="h5"
                mb={1}
                sx={{
                  color: "#818181",
                  fontFamily: "Montserrat",
                  fontSize: { xs: "13px", sm: "15px" },
                  fontWeight: "500",
                  lineHeight: {
                    xs: "16px",
                    md: matchesTwo ? "16px" : "20px",
                  },
                }}
              >
                Share your unique referral link and get 10% commission credited
                automatically in FLR from every transaction
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default HeroSection;

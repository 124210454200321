import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  RoadmapBg,
  hBg,
  roadmap,
  singleRoadmapitemBg,
} from "../components/SmallComponents/Images";

const RoadMap = () => {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const matches = useMediaQuery("(max-width:1250px)");
  const matchesTwo = useMediaQuery("(max-width:1350px)");
  const matchesThree = useMediaQuery("(max-width:1050px)");
  const smMatches = useMediaQuery("(max-width:970px)");
  const smMatchesTwo = useMediaQuery("(max-width:770px)");
  const smMatchesThree = useMediaQuery("(max-width:670px)");
  const xsMatches = useMediaQuery("(max-width:500px)");
  const xsMatchesOne = useMediaQuery("(max-width:432px)");
  const xsMatchesTwo = useMediaQuery("(max-width:370px)");
  const roadmapData = [
    {
      title: "Phase 1",
      textArray: [
        "Token Creation",
        "Presale Creation",
        "Largest Marketing Campaign on the Network to Launch",
        "Full Whitepaper published",
        "Presale launch",
      ],
    },
    {
      title: "Phase 2",
      textArray: [
        "DEX Launch",
        "Blazeswap Listing",
        "Marketing Campaign enters its biggest strategic growth phase",
        "PR campaign enters wider scope and reach",
      ],
    },

    {
      title: "Phase 3",
      textArray: [
        "CoinGecko listing",
        "Announcement on further DEX listings",
        " Airdrop reveal for early presale supporters",
        "Further marketing with additional cross-chain influencers",
      ],
    },
    {
      title: "Phase 4",
      textArray: ["Community Events", "CEX Listing", " Much much more..."],
    },
  ];
  return (
    <Box id="roadmap">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          mt={6}
          sx={{
            color: "#ffffff",
            backgroundImage: `url(${hBg})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            textAlign: "center",
            height: "fit-content",
            width: "fit-content",
          }}
        >
          <Typography
            px={{ xs: 3, md: 5 }}
            py={2}
            ml={2}
            variant="h5"
            sx={{
              fontFamily: "Wagoon Bold",
              fontSize: { xs: "24px", md: "48px" },
              fontWeight: "400",
              lineHeight: "48px",
            }}
          >
            RoadMap
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          pb: 1,
          backgroundImage: `url(${RoadmapBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: { xs: "110% 100%", sm: "100% 100%" },
          backgroundPosition: "center",
        }}
      >
        <Box
          mt={3}
          pt={10}
          sx={{
            position: "relative",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              width: 1,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "none",
            },
            "&::-webkit-scrollbar:horizontal": {
              height: "0px !important",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#3d3e40",
              borderRadius: 2,
            },
          }}
        >
          {roadmapData.map(({ title, textArray }, index) => (
            <Box
              data-aos={
                index === 0 || index === 1
                  ? mobileMatches
                    ? "fade-down"
                    : "fade-right"
                  : mobileMatches
                  ? "fade-down"
                  : "fade-left"
              }
              data-aos-delay="30"
              data-aos-duration="2000"
              key={index * Math.random() * 1}
              sx={{
                zindex: 0,
                backgroundImage: `url(${singleRoadmapitemBg})`,
                // backgroundColor: "#ffffff",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                backgroundPosition: "center",
                height: "200px",
                width: "280px",
                p: 2,
                textAlign: "center",
                position: "absolute",
                top:
                  index === 0
                    ? {
                        xs: xsMatchesOne ? "12.2%" : "7.5%",
                        sm: "11.8%",
                        md: matches ? "12.5%" : "13.4%",
                        lg: matchesTwo ? "12.5%" : "13%",
                        xl: "11.8%",
                      }
                    : index === 1
                    ? {
                        xs: "19.2%",
                        sm: "18.9%",
                        md: matches ? "19%" : "19.5%",
                        lg: "19.2%",
                        xl: "19%",
                      }
                    : index === 2
                    ? {
                        xs: "25.2%",
                        sm: "25%",
                        md: "25%",
                        lg: "25.3%",
                        xl: "25.1%",
                      }
                    : index === 3
                    ? {
                        xs: xsMatchesOne
                          ? xsMatchesTwo
                            ? "43.8%"
                            : "44.5%"
                          : "43.5%",
                        sm: "44%",
                        md: "44.2%",
                        lg: "43.5%",
                        xl: "44.1%",
                      }
                    : "none",
                left:
                  index === 0
                    ? {
                        xs: xsMatches ? (xsMatchesOne ? "22%" : "19%") : "17%",
                        sm: "13%",
                        md: matchesThree ? (smMatches ? "11%" : "10%") : "8%",
                        xl: "5%",
                        lg: matchesTwo ? "8%" : "5%",
                      }
                    : index === 1
                    ? {
                        xs: xsMatches ? (xsMatchesOne ? "120%" : "97%") : "85%",
                        sm: smMatchesTwo
                          ? smMatchesThree
                            ? "66%"
                            : "60%"
                          : "56%",
                        md: matchesThree ? (smMatches ? "49%" : "46%") : "40%",

                        xl: "30%",
                        lg: matchesTwo ? "37%" : "30%",
                      }
                    : index === 2
                    ? {
                        xs: xsMatches
                          ? xsMatchesOne
                            ? "218%"
                            : "170%"
                          : "153%",
                        sm: smMatchesTwo
                          ? smMatchesThree
                            ? "120%"
                            : "107%"
                          : "98%",
                        md: matchesThree ? (smMatches ? "87%" : "80%") : "70%",
                        xl: "53%",
                        lg: matchesTwo ? "64%" : "54%",
                      }
                    : index === 3
                    ? {
                        xs: xsMatches
                          ? xsMatchesOne
                            ? "310%"
                            : "250%"
                          : "217%",
                        sm: smMatchesTwo
                          ? smMatchesThree
                            ? "170%"
                            : "155%"
                          : "138%",

                        md: matchesThree
                          ? smMatches
                            ? "123%"
                            : "115%"
                          : "100%",
                        xl: "75%",
                        lg: matchesTwo ? "91%" : "77%",
                      }
                    : "none",
              }}
            >
              <Box px={2} sx={{ textAlign: "left" }}>
                <Typography
                  pb={1}
                  sx={{
                    fontFamily: "Wagoon Bold",
                    fontSize: "25px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#EE7D08",
                  }}
                >
                  {title}
                </Typography>
                {textArray?.map((text, i) => (
                  <Box
                    sx={{ textAlign: "left" }}
                    key={text + i + Math.random()}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Wagoon Bold",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "23px",
                        color: "#EE7D08",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 0.7,
                      }}
                    >
                      <span
                        style={{
                          marginLeft: "-10px",
                          fontWeight: "700",
                          fontSize: "26px",
                        }}
                      >
                        -
                      </span>
                      {text}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}

          <Box
            sx={{
              mt: 30,
              height: "100%",
              width: {
                xs: xsMatches ? (xsMatchesOne ? "400%" : "330%") : "280%",
                sm: smMatchesTwo ? (smMatchesThree ? "230%" : "205%") : "180%",
                md: matchesThree ? (smMatches ? "160%" : "150%") : "130%",
                lg: matchesTwo ? "120%" : "100%",
                xl: "100%",
              },
              zindex: 1,
            }}
          >
            <Box
              component={"img"}
              src={roadmap}
              alt="roadmap"
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RoadMap;

import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  copyChain,
  copyIcon,
  logo,
} from "../components/SmallComponents/Images";
import { ToastNotify } from "../components/SmallComponents/AppComponents";

function AddressSection() {
  const address = "0x054bca17321a8114c97ebb9559b658d24702428b";
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #FED319 -26.27%, #EE7D08 133.33%)",
        mt: { xs: -0.9, sm: -1.3, md: -1.5, lg: -1.6, xl: -1.8 },
        py: { xs: 5, sm: 10 },
      }}
    >
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container maxWidth="xl">
        <Stack
          flexDirection={{ xs: "coloumn", md: "row" }}
          justifyContent={"center"}
          gap={3}
        >
          <Stack flexDirection={"row"} gap={1} flexGrow={1} height={"100%"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                mt: 1,
              }}
            >
              <Box
                component={"img"}
                alt=""
                src={logo}
                width={{ xs: "50px", sm: "60px" }}
              />
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Wagoon Bold",
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: "400",
                  color: "#373737",
                }}
              >
                CONTRACT ADDRESS
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "10px", sm: "16px" },
                  fontWeight: "700",
                  color: "#ffffff",
                  mt: 1,
                }}
              >
                {address}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: { xs: "9px", sm: "12px" },
                  fontWeight: "500",
                  color: "#ffffff",
                  mt: 0.5,
                  lineHeight: "13px",
                }}
              >
                Note: Do not send any money to the contract address! This is
                just for importing the token or verifying
              </Typography>
            </Box>
          </Stack>
          <Stack
            flexDirection={"row"}
            mt={{ xs: 1, md: 0 }}
            flexGrow={1}
            justifyContent={"space-between"}
          >
            <Stack flexDirection={"row"} gap={1}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Wagoon Bold",
                    fontSize: { xs: "14px", sm: "16px" },
                    fontWeight: "400",
                    color: "#373737",
                  }}
                >
                  NETWORK
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "12px", sm: "16px" },
                    fontWeight: "700",
                    color: "#ffffff",
                    mt: 1,
                  }}
                >
                  Flare Networks
                </Typography>
              </Box>
            </Stack>
            <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
              <Box
                component={"img"}
                alt=""
                src={copyChain}
                width={{ xs: "35px", sm: "40px" }}
              />
              <Button
                endIcon={
                  <Box
                    component={"img"}
                    alt={""}
                    src={copyIcon}
                    width={"20px"}
                  />
                }
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#5C5C5C",
                  fontFamily: "Montserrat",
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: "600",
                  width: { xs: "150px", sm: "180px" },
                  borderRadius: "30px",
                  height: "50px",
                  border: "1px solid #5C5C5C",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(address);
                  showAlert("Address Copied!", "success");
                }}
              >
                COPY ADDRESS
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default AddressSection;

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TimerCountDown from "./PresaleTimer";
import CircleIcon from "@mui/icons-material/Circle";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { StyledInput, ToastNotify } from "./AppComponents";

import { ethIcon, mobilePresaleBoxBg, preSaleBoxBg, smallLogo } from "./Images";
import { AppContext } from "../../utils";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  tokenReadFunction,
} from "../../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "viem";
import Loading from "./loading";
import { HashLink } from "react-router-hash-link";

function PresaleBox() {
  const { account, callFunction, setCallFunction } = useContext(AppContext);
  const { open } = useWeb3Modal();
  const matches = useMediaQuery("(max-width:1050px)");
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const mobileMatchesTwo = useMediaQuery("(max-width:400px)");
  const matchesTwo = useMediaQuery("(max-width:1350px)");
  let stageEndTime = 1717232400;
  const [buyWith, setBuyWith] = useState("FLR");
  const [preSaleEndedStatus, setPresaleEndedStatus] = useState(false);
  const [amount, setAmount] = useState("");
  const [recivedTokens, setreceivedTokens] = useState(0);
  const [tokenPerETH, settokenPerETH] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [currentStage, setcurrentStage] = useState(0);
  const [loading, setloading] = useState(false);
  const [amountRaisedForAll, setamountRaisedForAll] = useState(0);
  const [totalSoldTokens, setTotalSoldTokens] = useState(0);
  const [progressBarForAll, setprogressBarForAll] = useState(0);
  const [fundsReceiver, setFundsReceiver] = useState("");
  const [userPurchasedTokens, setuserPurchasedTokens] = useState(0);
  const [isPresaleStart, setisPresaleStart] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  useEffect(() => {
    (() => {
      if (window.location.href.includes("?ref=")) {
        let getAddress = window.location.href.split("?ref=")[1];
        let final = getAddress.slice(0, 42);
        localStorage.setItem("SHIBA", final);
        setCallFunction(false);
      }
    })();
  }, [callFunction]);
  const initVoidSigner = async () => {
    try {
      let dec = await tokenReadFunction("decimals");
      let stage = await preSaleReadFunction("currentStage");
      let start = await preSaleReadFunction("presaleStatus");
      setisPresaleStart(start);
      let fundReceiver = await preSaleReadFunction("fundReceiver");
      setFundsReceiver(fundReceiver);
      setcurrentStage(+stage?.toString());
      let ethToToken = await preSaleReadFunction("nativeToToken", [
        "1000000000000000000",
        stage?.toString(),
      ]);
      settokenPerETH(+formatUnits(ethToToken?.toString(), +dec?.toString()));
      let presaleData = await preSaleReadFunction("phases", [
        +stage?.toString(),
      ]);
      let soldForStage = +parseFloat(
        +formatUnits(presaleData[1]?.toString(), +dec?.toString())
      )?.toFixed(0);
      let toSellForStage = +parseFloat(
        +formatUnits(presaleData[0]?.toString(), +dec?.toString())
      )?.toFixed(0);
      let progForAll = (+soldForStage / toSellForStage) * 100;
      setprogressBarForAll(+progForAll);
      settokenPrice(+formatUnits(presaleData[2]?.toString(), +dec?.toString()));
      let totalRaisedAmount = 0;
      let totalTokeSoldContract = 0;
      for (let index = 0; index <= +stage?.toString(); index++) {
        let presaleData = await preSaleReadFunction("phases", [+index]);
        totalRaisedAmount += +parseFloat(
          +formatUnits(presaleData[1]?.toString(), +dec?.toString()) /
            +formatUnits(presaleData[2]?.toString(), +dec?.toString())
        ).toFixed(0);
        totalTokeSoldContract += +parseFloat(
          +formatUnits(presaleData[1]?.toString(), +dec?.toString())
        )?.toFixed(0);
      }
      setamountRaisedForAll(toLocalFormat(+totalRaisedAmount));
      setTotalSoldTokens(toLocalFormat(+totalTokeSoldContract));

      const preSaleStatusContract = await preSaleReadFunction("isPresaleEnded");
      setPresaleEndedStatus(preSaleStatusContract);
    } catch (error) {
      console.log(error, "ERROR VoidSigner Data");
    }
  };
  useEffect(() => {
    initVoidSigner();
    setCallFunction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction]);

  useEffect(() => {
    if (account) {
      (async () => {
        try {
          let dec = await tokenReadFunction("decimals");
          let userData = await preSaleReadFunction("users", [account]);
          console.log(userData, "userData");
          setuserPurchasedTokens(
            +parseFloat(
              formatUnits(userData[1]?.toString(), +dec?.toString())
            )?.toFixed(0)
          );
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [account, callFunction]);

  const buyHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    if (!amount || amount <= 0) {
      return showAlert("Error! Please enter amount to buy.");
    }
    try {
      let refAddress = localStorage.getItem("SHIBA");
      let refAdd = refAddress ? refAddress : fundsReceiver;
      setloading(true);
      await preSaleWriteFunction(
        "buyToken",
        [refAdd],
        parseUnits(amount.toString(), 18).toString()
      );
      setAmount("");
      setreceivedTokens(0);
      setCallFunction(true);
      setloading(false);
      showAlert("Success! Transaction Confirmed", "success");
    } catch (error) {
      setloading(false);
      showAlert(error?.shortMessage);
    }
  };

  useEffect(() => {
    const calculatorUSDT = async () => {
      try {
        let tokenETH = +tokenPerETH * +amount;
        setreceivedTokens(tokenETH?.toFixed(2));
      } catch (error) {}
    };
    if (+amount > 0) {
      calculatorUSDT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, buyWith]);

  const claimHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    try {
      setloading(true);
      await preSaleWriteFunction("claimTokens");
      setCallFunction(true);
      setloading(false);
      showAlert("Success! Transaction Confirmed", "success");
    } catch (error) {
      setloading(false);
      showAlert(error?.shortMessage);
    }
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    const newValue = input?.replace(/[^0-9.]/g, "");
    setAmount(newValue);
  };

  return (
    <>
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Box
        data-aos={mobileMatches ? "fade-down" : "fade-left"}
        data-aos-delay="30"
        data-aos-duration="2000"
        sx={{
          mb: { xs: 4, sm: 0 },
          ml: { xs: 2, sm: 0 },
          backgroundImage: {
            xs: `url(${mobilePresaleBoxBg})`,
            sm: `url(${preSaleBoxBg})`,
          },
          backgroundSize: { xs: "110% 100%", sm: "100% 100%" },
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          px: {
            xs: mobileMatchesTwo ? 8 : 9,
            sm: 22,
            md: matches ? 25 : 18,
            xl: 25,
            lg: matchesTwo ? 21 : 23,
          },
          pt: { xs: 18, sm: 15 },
          pb: { xs: 15, sm: 14 },
        }}
      >
        <Box>
          <Stack
            flexDirection={{ xs: "column-reverse", sm: "row" }}
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems={{ xs: "center", sm: "start" }}
            gap={{ xs: 1, sm: 0 }}
            my={1}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Wagoon Bold",
                fontSize: { xs: "20px", sm: "26px" },
                fontWeight: "400",
                color: "#ffffff",
              }}
            >
              GET IN EARLY!
            </Typography>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
              <Box
                sx={{
                  borderRadius: "50px",
                  backgroundColor: "#D71C26",
                  py: 1,
                  px: 1,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "10px", sm: "12px" },
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                >
                  Stage {currentStage + 1}
                </Typography>
              </Box>
              {isPresaleStart && (
                <Box
                  sx={{
                    borderRadius: "50px",
                    backgroundColor: "#21C107",
                    py: 1,
                    px: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: { xs: "10px", sm: "12px" },
                      fontWeight: "600",
                      color: "#ffffff",
                    }}
                  >
                    Live
                  </Typography>
                </Box>
              )}
            </Stack>
          </Stack>
          <Stack
            sx={{
              height: "30px",
              boxShadow:
                "5.091511249542236px 10.183022499084473px 15.274534225463867px 0px #00000080",
              background: "#F3DC09",
              mt: 0.5,
              borderRadius: "20px",
              border: "1px solid #EE7D08",
              overflow: "hidden",
              mx: { xs: 0, sm: 1 },
            }}
          >
            <Stack
              height={"100%"}
              alignItems={"start"}
              justifyContent={"center"}
              position={"relative"}
            >
              <Box
                sx={{
                  position: "absolute",
                  boxShadow:
                    "4.073208808898926px 4.073208808898926px 10.183022499084473px 0px #FFFFFFCC inset,0px 4.493834972381592px 9.986300468444824px 0px #A201274D",
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: `${progressBarForAll}%`,
                  borderRadius: "20px",
                  backgroundColor: "#D71C26",
                  zIndex: 0,
                }}
              />
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#ffff",
                  fontFamily: "Montserrat",
                  fontSize: "11px",
                  fontWeight: "600",
                  zIndex: 1,
                  pl: 1.2,
                }}
              >
                {parseFloat(progressBarForAll)?.toFixed(2)}%
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"flex-end"} my={1}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#ffff",
                fontFamily: "Montserrat",
                fontSize: { xs: "10px", sm: "12px" },
                fontWeight: "600",
                pr: 1.2,
              }}
            >
              TOTAL RAISED FLR {amountRaisedForAll}
            </Typography>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              my: 1,
            }}
          >
            <Box
              sx={{
                height: "2px",
                // width: "50%",
                background: "#EE7D08",
                flexGrow: 1,
              }}
            />
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", sm: "row" }}
              gap={{ xs: 0.5, sm: 1, md: 0.8, xl: 1, lg: 1 }}
              mx={{ xs: 1, md: 0.5, lg: 1, xl: 1 }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: { xs: "10px", sm: "12px" },
                  textAlign: "center",
                  fontWeight: "700",
                  color: "#ffff",
                  fontFamily: "Montserrat",
                }}
              >
                1 $BLAST = FLR{" "}
                {+tokenPrice > 0
                  ? parseFloat(1 / +tokenPrice).toFixed(2)
                  : "0.00"}
              </Typography>

              <CircleIcon
                sx={{
                  color: "#D9D9D9",
                  fontSize: "9px",
                }}
              />
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: { xs: "10px", sm: "12px" },
                  textAlign: "center",
                  fontWeight: "700",
                  color: "#ffff",
                  fontFamily: "Montserrat",
                }}
              >
                LISTING PRICE = FLR 0.3
              </Typography>
            </Box>

            <Box
              sx={{
                height: "2px",
                flexGrow: 1,
                background: "#EE7D08",
              }}
            />
          </Stack>

          <Stack mt={1}>
            <TimerCountDown time={+stageEndTime} />
          </Stack>
          <Stack
            my={1}
            sx={{
              mx: { xs: 0, sm: 1 },
              px: 2,
              py: 1,
              borderRadius: "12px",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 1, sm: 0 },
              // justifyContent: "space-around",
              background:
                "linear-gradient(180deg, #F3DC09 0%, rgba(243, 150, 9, 0.94) 100%)",
              boxShadow:
                "0px 3.8890788555145264px 24.30674171447754px 0px #0000004D",
            }}
          >
            <Stack flexGrow={1}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#ffff",
                  fontFamily: "Montserrat",
                  fontSize: "10px",
                  fontWeight: "700",
                }}
              >
                TOKENS SOLD
              </Typography>
              <Stack flexDirection={"row"} alignItems="center" my={1} gap={0.5}>
                <Box
                  height={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    component={"img"}
                    alt=""
                    src={smallLogo}
                    sx={{ width: "40px", mt: { xs: 1, sm: 0 } }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#ffff",
                      fontFamily: "Montserrat",
                      fontSize: { xs: "14px", sm: "13px" },
                      fontWeight: "700",
                    }}
                  >
                    {totalSoldTokens}
                    <br />
                    {/* <span
                      style={{
                        fontWeight: "600",
                        fontSize: { xs: "10px", sm: "9px" },
                      }}
                    >
                      / 1,500,000,000
                    </span> */}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            {mobileMatches ? (
              <Divider
                sx={{
                  background: "#353535",
                }}
              />
            ) : (
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  background: "#353535",
                  mr: 1,
                }}
              />
            )}

            <Stack flexGrow={1}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#ffff",
                  fontFamily: "Montserrat",
                  fontSize: "10px",
                  fontWeight: "700",
                }}
              >
                FLR RAISED
              </Typography>
              <Stack
                flexDirection={"row"}
                alignItems="center"
                mt={1.6}
                gap={0.5}
              >
                <Box
                  height={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    component={"img"}
                    alt=""
                    src={ethIcon}
                    sx={{ width: "30px", mt: { xs: 1, sm: 0 } }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#ffff",
                      fontFamily: "Montserrat",
                      fontSize: { xs: "14px", sm: "13px" },
                      fontWeight: "700",
                    }}
                  >
                    {amountRaisedForAll}
                    <br />
                    {/* <span
                      style={{
                        fontWeight: "600",
                        fontSize: { xs: "10px", sm: "9px" },
                      }}
                    >
                      / $975,000
                    </span> */}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              my: 1,
            }}
          >
            <Box
              sx={{
                height: "2px",
                // width: "50%",
                background: "#EE7D08",
                flexGrow: 1,
              }}
            />

            <Typography
              variant="subtitle2"
              sx={{
                fontSize: { xs: "10px", sm: "13px" },
                textAlign: "center",
                fontWeight: "700",
                color: "#ffff",
                fontFamily: "Montserrat",
                mx: 2,
                // width: "120px",
              }}
            >
              {account ? "BUY" : "CONNECT WALLET"}
            </Typography>

            <Box
              sx={{
                height: "2px",
                flexGrow: 1,
                // width: "50%",
                background: "#EE7D08",
              }}
            />
          </Stack>
          <Stack
            my={1}
            sx={{
              mx: 1,
              borderRadius: "12px",
              flexDirection: "row",
              background:
                "linear-gradient(180deg, #F3DC09 0%, rgba(243, 150, 9, 0.94) 100%)",
              boxShadow:
                "0px 3.8890788555145264px 24.30674171447754px 0px #0000004D",
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              {[
                {
                  text: "FLR",
                  img: ethIcon,
                },
              ].map(({ text, img }) => (
                <Stack
                  onClick={() => setBuyWith(text)}
                  key={text}
                  sx={{
                    cursor: "pointer",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    width: "100%",
                    py: { xs: 1, sm: 2 },
                    boxShadow:
                      buyWith === text
                        ? "0px 3.5036327838897705px 21.897705078125px 0px #0000004D"
                        : "none",

                    border: buyWith === text ? "0.44px solid #E5CF08" : "none",
                    borderRadius: "12px",
                    background:
                      buyWith === text
                        ? "linear-gradient(100deg, #D71C26, #D71C26),linear-gradient(0deg, #E5CF08, #E5CF08)"
                        : "transparent",
                  }}
                >
                  <Box component={"img"} alt="" src={img} width="22px" />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#ffff",
                      fontFamily: "Montserrat",
                      fontSize: { xs: "9px", sm: "11px" },
                      fontWeight: "700",
                    }}
                  >
                    {`BUY WITH ${text}`}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 1, sm: 2 },
              my: 2,
            }}
          >
            <Box>
              <StyledInput
                type="text"
                placeholder="Enter Amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <Box
                        component={"img"}
                        alt=""
                        src={ethIcon}
                        style={{
                          width: "22px",
                          marginLeft: "-10px",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={amount}
                onChange={handleInputChange}
              />
            </Box>
            <Box>
              <StyledInput
                type="number"
                placeholder="0"
                value={amount > 0 ? recivedTokens : "0"}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="end">
                      <Box
                        component={"img"}
                        alt=""
                        src={smallLogo}
                        style={{
                          width: "30px",
                          marginLeft: "-10px",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
          <Stack my={1} gap={2}>
            {!preSaleEndedStatus && (
              <Button
                onClick={async () => (account ? buyHandler() : await open())}
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: "600",
                  background: "#D71C26",
                  color: "#ffffff",
                  borderRadius: "12px",
                  height: "50px",
                  boxShadow:
                    "0px 3.449824571609497px 21.561405181884766px 0px #A201274D",
                  "&:hover": { background: "#D71C26" },
                }}
              >
                {account ? "BUY" : "CONNECT"}
              </Button>
            )}

            {preSaleEndedStatus && account ? (
              <Button
                onClick={async () => claimHandler()}
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: "600",
                  background: "#EE7D08",
                  color: "#ffffff",
                  borderRadius: "12px",
                  height: "50px",
                  boxShadow:
                    "0px 3.449824571609497px 21.561405181884766px 0px #A201274D",
                  "&:hover": { background: "#EE7D08" },
                }}
              >
                Claim
              </Button>
            ) : null}
          </Stack>

          {/* User Purchased Token Here */}

          <Stack
            mt={2}
            mb={1}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              variant="h5"
              sx={{
                color: "#EE7D08",
                fontFamily: "Montserrat",
                fontSize: "15px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                ml: 0.5,
              }}
            >
              Your $Blast balance: {toLocalFormat(userPurchasedTokens)}
            </Typography>
          </Stack>

          <Stack
            mt={1}
            mb={2}
            ml={0.5}
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <HashLink
              to="/#how-to-buy"
              style={{
                textDecoration: "none",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#EE7D08",
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                How to Buy{" "}
              </Typography>
            </HashLink>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
              <Box component={"img"} alt="" src={ethIcon} width={"30px"} />
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default PresaleBox;

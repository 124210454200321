import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  cloud1,
  cloud2,
  cloud3,
  doghouseImg,
  KnowBg,
} from "../components/SmallComponents/Images";

const WannaKnow = () => {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  return (
    <Box
      py={10}
      sx={{
        backgroundColor: "#FEC80E",
        position: "relative",
      }}
    >
      <Box
        className="clouds"
        sx={{
          width: { xs: "20%", md: "10%" },
          position: "absolute",
          top: { xs: "2%", sm: "6%" },
          zIndex: 0,
        }}
      >
        <Box component={"img"} src={cloud1} sx={{ width: "100%" }} />
      </Box>
      <Box
        className="clouds"
        sx={{
          width: { xs: "45%", sm: "40%", md: "17%" },
          position: "absolute",
          top: { xs: "8%", sm: "6%", md: "10%" },
          left: { xs: "25%", md: "45%" },
          zIndex: 0,
        }}
      >
        <Box component={"img"} src={cloud2} sx={{ width: "100%" }} />
      </Box>
      <Box
        className="clouds"
        sx={{
          width: { xs: "40%", sm: "24%", md: "12%" },
          position: "absolute",
          top: { xs: "3%", sm: "10%", md: "25%" },
          right: { xs: "2%", sm: "5%" },
          zIndex: 0,
        }}
      >
        <Box component={"img"} src={cloud3} sx={{ width: "100%" }} />
      </Box>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              data-aos={mobileMatches ? "fade-up" : "fade-right"}
              data-aos-delay="30"
              data-aos-duration="2000"
              sx={{
                zIndex: 1,
                py: { xs: 5, md: 10 },
                backgroundImage: `url(${KnowBg})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "340px",
                width: "590px",
                textAlign: "center",
                alignItems: "center",
                mt: { xs: 3, md: 0 },
              }}
            >
              <Typography
                variant="h5"
                pt={15}
                px={5}
                sx={{
                  color: "#ffffff",
                  fontFamily: "Wagoon Bold",
                  fontSize: { xs: "20px", md: "35px" },
                  fontWeight: "400",
                }}
              >
                Wanna Know More?
              </Typography>
              <Typography
                variant="h5"
                pt={2}
                sx={{
                  px: { xs: 10, sm: 15 },
                  textAlign: "center",
                  color: "#ffffff",
                  fontFamily: "Montserrat",
                  fontSize: { xs: "10px", sm: "12px" },
                  fontWeight: "400",
                  lineHeight: "13px",
                }}
              >
                There are lots more interesting facts about BLAST you should
                know before entering the rocket! Just follow BLAST and he will
                show you the Tokenomics, Roadmap, his very own trailer and much
                more!
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              mt: { xs: 10, md: 0 },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              data-aos={mobileMatches ? "fade-down" : "fade-left"}
              data-aos-delay="30"
              data-aos-duration="2000"
              zIndex={1}
              // height={400}
              // width={500}
              sx={{
                height: { xs: 450, md: 500 },
                width: { xs: "100%", md: 450 },
              }}
            >
              <Box
                component="img"
                src={doghouseImg}
                height="100%"
                width="100%"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WannaKnow;

import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  allstarBg,
  ethIcon,
  hBg,
  starBoard,
} from "../components/SmallComponents/Images";
import { AppContext } from "../utils";
import {
  preSaleReadFunction,
  tokenReadFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits } from "viem";

const winnerArray = [
  {
    winnerNo: 1,
    usdtAmount: 0,
    ethAmount: 0,
    pugAmount: 0,
    amountValue: 0,
  },
  {
    winnerNo: 2,
    usdtAmount: 0,
    ethAmount: 0,
    pugAmount: 0,
    amountValue: 0,
  },
  {
    winnerNo: 3,
    usdtAmount: 0,
    ethAmount: 0,
    pugAmount: 0,
    amountValue: 0,
  },
];

const AllStar = () => {
  const { callFunction, setCallFunction } = useContext(AppContext);
  const [winnerData, setWinnerData] = useState(winnerArray);

  useEffect(() => {
    (async () => {
      try {
        const tokenDecimal = await tokenReadFunction("decimals");
        const dt = [];
        for (let i = 0; i < 3; i++) {
          const buyerContractAddress = await preSaleReadFunction(
            "topBuyersData",
            [i]
          );
          const userDataContract = await preSaleReadFunction("users", [
            buyerContractAddress[0],
          ]);
          const userEthValue = +parseFloat(
            +formatUnits(userDataContract[0]?.toString(), 18)
          ).toFixed(0);

          const obj = {
            ethAmount: userEthValue,
            pugAmount: +parseFloat(
              +formatUnits(
                userDataContract[1]?.toString(),
                +tokenDecimal?.toString()
              )
            ).toFixed(2),
          };
          dt.push(obj);
        }
        const newSortArray = dt?.sort((a, b) => b?.pugAmount - a?.pugAmount);
        setWinnerData(newSortArray);
      } catch (err) {
        console.log(err);
      }
    })();
    setCallFunction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction]);
  return (
    <Box mt={5}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          mt={6}
          sx={{
            color: "#ffffff",
            backgroundImage: `url(${hBg})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            py: { xs: 2, sm: 4 },
            px: { xs: 5, sm: 10 },
            textAlign: "center",
            height: "fit-content",
            width: "fit-content",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Wagoon Bold",
              fontSize: { xs: "24px", md: "48px" },
              fontWeight: "400",
              lineHeight: "48px",
            }}
          >
            Shiba Blast All-Stars
          </Typography>
        </Box>
      </Box>
      <Box
        pb={5}
        sx={{
          backgroundImage: `url(${allstarBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: { xs: "120% 90%", sm: "100% 100%" },
          height: "865px",
          width: "100%",

          backgroundPosition: "center",
        }}
      >
        <Box
          pt={3}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "13px",
              letterSpacing: "0em",
              textAlign: "center",
            }}
          >
            The top 3 BLAST members will receive an extra token airdrop. These
            are the VIP
            <br /> members of our community and should be treated with respect!
          </Typography>
        </Box>
        <Box
          data-aos="fade-up"
          data-aos-delay="30"
          data-aos-duration="2000"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {winnerData.map(({ ethAmount, pugAmount }, index) => (
            <Box
              key={index * Math.random() * 4}
              sx={{
                width: { xs: "280px", sm: "550px" },
                height: "90px",

                borderRadius: "12px",
                alignItems: "center",
                display: "flex",
                position: "absolute",

                top:
                  index === 0
                    ? "20%"
                    : index === 1
                    ? "36%"
                    : index === 2
                    ? { xs: "51%", sm: "52%" }
                    : null,
              }}
            >
              <Box ml={{ xs: 1, sm: 4 }}>
                <Box
                  component={"img"}
                  src={ethIcon}
                  sx={{ width: { xs: "25px", sm: "30px" } }}
                />
              </Box>
              <Stack
                direction={"column"}
                sx={{ ml: { xs: 1, md: 2 } }}
                width={"100%"}
              >
                <Typography
                  sx={{
                    fontFamily: "Wagoon Bold",
                    fontSize: { xs: "14px", sm: "24px" },
                    fontWeight: 400,
                    lineHeight: { xs: "20px", sm: "30px" },
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#ffffff",
                  }}
                >
                  FLR
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "10px", sm: "15px" },
                    fontWeight: 600,
                    lineHeight: { xs: "20px", sm: "30px" },
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#ffffff",
                  }}
                >
                  FLR {ethAmount}
                </Typography>
              </Stack>
              <Stack
                flexDirection={"row"}
                justifyContent={"flex-end"}
                width={"100%"}
                sx={{ width: "100%", px: { xs: 0, sm: 5 } }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: { xs: "10px", sm: "17px" },
                    fontWeight: 700,
                    lineHeight: "46px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#ffffff",
                  }}
                >
                  {pugAmount} BLAST
                </Typography>
              </Stack>
            </Box>
          ))}

          <Box
            pt={7}
            sx={{
              height: { xs: "500px", sm: "704px" },
              width: { xs: "300px", sm: "600px" },
            }}
          >
            <Box
              component="img"
              src={starBoard}
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AllStar;

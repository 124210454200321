import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import {
  cloudBg,
  hBg,
  stepsBg,
  singlestepBg,
  walletIcon,
  amountIcon,
  confirmIcon,
  grassBg,
} from "../components/SmallComponents/Images";

const HowToBuy = () => {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const stepsData = [
    {
      title: "Connect your wallet",
      description:
        " Click on “Connect Wallet” using your usual decentralized wallet to verify. Make sure you are on the Flare Network and have some FLR for fees ready.",
      logo: walletIcon,
    },
    {
      title: "Enter the amount",
      description:
        "Either enter the amount of FLR you wish to swap. Sometimes you may need to refresh the page or wait a moment for the BLAST received to appear, this is normal.",
      logo: amountIcon,
    },
    {
      title: "Confirm and Go",
      description:
        " Click on “Confirm” and verify the transaction inside your wallet. Now wait for the presale to finish and then come back to claim your BLAST.",
      logo: confirmIcon,
    },
  ];
  return (
    <Box mt={10} id="how-to-buy">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box
          mb={6}
          sx={{
            color: "#ffffff",
            backgroundImage: `url(${hBg})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            py: { xs: 1, sm: 2 },
            px: 10,
            textAlign: "center",
            height: "fit-content",
            width: "fit-content",
            zIndex: 1,
          }}
        >
          <Typography
            mt={{ xs: 1, sm: 3 }}
            variant="h5"
            sx={{
              fontFamily: "Wagoon Bold",
              fontSize: { xs: "28px", md: "48px" },
              fontWeight: "400",
              lineHeight: "48px",
            }}
          >
            HOW TO BUY
          </Typography>
          <Typography
            variant="body2"
            pb={2}
            sx={{
              fontFamily: "Wagoon Bold",
              fontSize: "17px",
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            Buy BLAST in 3 steps
          </Typography>
        </Box>
        <Box
          className="clouds"
          component="img"
          src={cloudBg}
          sx={{
            position: "absolute",
            right: { xs: "0%", sm: "5%", md: "10%" },
            bottom: { xs: "100%", md: "0%" },
            zIndex: 0,
          }}
        />
        <Box
          className="clouds"
          component="img"
          src={cloudBg}
          sx={{
            position: "absolute",
            left: { xs: "0%", md: "10%" },
            top: { xs: "80%", md: "0%" },
            zIndex: 0,
          }}
        />
      </Box>
      <Container maxWidth="xl">
        <Grid
          container
          pt={5}
          pb={5}
          sx={{
            mt: { xs: 10, md: 5 },
            display: "flex",
            justifyContent: "center",
            color: "#ffffff",
            backgroundImage: `url(${stepsBg})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",

            height: "fit-content",
            width: "fit-content",
          }}
        >
          {stepsData.map(({ title, description, logo }, index) => (
            <Grid
              item
              xs={12}
              md={4}
              pt={10}
              key={index}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                data-aos={
                  index === 0
                    ? mobileMatches
                      ? "fade-down"
                      : "fade-right"
                    : index === 1
                    ? "fade-up"
                    : mobileMatches
                    ? "fade-up"
                    : "fade-left"
                }
                data-aos-delay="30"
                data-aos-duration="2000"
                sx={{
                  width: "300px",
                  height: "180px",
                  color: "#ffffff",
                  backgroundImage: `url(${singlestepBg})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  textAlign: "center",

                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  height={52}
                  width={52}
                  src={logo}
                  sx={{
                    position: "relative",
                    top: "-15%",
                  }}
                />

                <Typography
                  px={5}
                  variant="h5"
                  sx={{
                    fontFamily: "Wagoon Bold",
                    fontSize: "19px",
                    fontWeight: "400",
                    lineHeight: "21px",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body2"
                  pt={1}
                  px={5}
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "11px",
                    fontWeight: "500",
                    lineHeight: "13px",
                  }}
                >
                  {description}
                </Typography>
              </Box>
            </Grid>
          ))}
          <Typography
            variant="body2"
            pt={10}
            px={5}
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: { xs: "10px", sm: "12px" },
              lineHeight: "13px",
            }}
          >
            Note: You can only buy BLAST on this website and nowhere else. Team
            members will never reach out to you first so please always check for
            the official links!
          </Typography>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={6}
            md={4}
            sx={{
              order: { xs: 2, md: 1 },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={grassBg}
              sx={{ height: { xs: 100, md: 180 }, width: { xs: 180, md: 240 } }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              order: { xs: 1, md: 2 },
              display: "flex",
              justifyContent: "center",
            }}
          ></Grid>
          <Grid
            item
            xs={6}
            md={4}
            sx={{
              order: { xs: 3, md: 3 },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={grassBg}
              sx={{ height: { xs: 100, md: 180 }, width: { xs: 180, md: 240 } }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HowToBuy;

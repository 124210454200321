import { Alert, Box, Snackbar, TextField } from "@mui/material";
import { Button } from "@mui/material";
import { walletBtnBg, whitePaperBtnBg } from "./Images";

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

export function StyledButton({ children, ...props }) {
  return (
    <>
      <Button
        {...props}
        disableRipple={true}
        sx={{
          color: "#ffffff",
          backgroundImage: `url(${
            props?.btnStatus ? whitePaperBtnBg : walletBtnBg
          })`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "transparent",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "capitalize",
          fontFamily: "Wagoon Bold",
          fontSize: "16px",
          fontWeight: "400",
          width: props?.width,
          height: props?.height ? props?.height : "60px",
          "&.Mui-disabled": {
            color: "#ffffff",
          },
          "&:hover": {
            color: props?.btnStatus ? "#1C1C1C" : "#ffffff",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}

export function StyledText({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#ffffff",
          fontSize: "18px",
          fontFamily: "Wagoon Bold",
          fontWeight: "400",
          mr: props.mr,
          cursor: "pointer",
          "&:hover": {
            color: "#1C1C1C",
          },
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledTitle({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "40px",
          fontFamily: "Wagoon Bold",
          fontWeight: "700",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}

export const StyledInput = ({ ...props }) => {
  return (
    <TextField
      {...props}
      sx={{
        boxShadow:
          "0px 3.4595067501068115px 21.621915817260742px 0px #0000004D",
        background:
          "linear-gradient(180deg, #F3DC09 0%, rgba(243, 150, 9, 0.94) 100%),linear-gradient(0deg, #505050, #505050)",
        borderRadius: "7px",
        border: "0.43px solid #505050",
        width: "100%",
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },

        input: {
          "&::placeholder": {
            color: "#ffffff !important",
            opacity: 1,
          },
          padding: { xs: "16px 6px", sm: "18px 4px" },
          color: "#ffffff",
          fontSize: "13px",
          fontWeight: "600",
          fontFamily: "Montserrat",
        },
      }}
    />
  );
};

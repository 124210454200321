import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { footerBg, logo } from "./SmallComponents/Images";
import { HashLink } from "react-router-hash-link";
import { socialArray } from "./Header";

const footerArray = [
  {
    head: "Navigation Links",
    textArray: [
      {
        name: "HOME",
        link: "/#home",
      },
      {
        name: "Presale Stages",
        link: "/#presale-stages",
      },
      {
        name: "Tokenomics",
        link: "/#tokenomics",
      },
      {
        name: "Roadmap",
        link: "/#roadmap",
      },
      {
        name: "FAQ's",
        link: "/#faqs",
      },
    ],
  },
  {
    head: "Legal",
    textArray: [
      {
        name: "Terms & Conditions",
        link: "/BLAST_Terms_And_Conditions.pdf",
        pdfStatus: true,
      },
      {
        name: "Privacy Policy",
        link: "/BLAST_Privacy_policy.pdf",
        pdfStatus: true,
      },
      {
        name: "Cookies",
        link: "/BLAST_Cookies_Policy.pdf",
        pdfStatus: true,
      },
    ],
  },
];

function Footer() {
  const matches = useMediaQuery("(max-width:900px)");
  return (
    <Box
      sx={{
        py: 6,
        background: `url(${footerBg}) no-repeat center`,
        backgroundSize: { xs: "150% 100%", sm: "100% 100%" },
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={4} px={3}>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "start" },
                alignItems: { xs: "center", md: "start" },
                flexDirection: "column",
              }}
            >
              <Box component={"img"} alt="" src={logo} width={"80px"} />
              <Typography
                variant="h5"
                my={2}
                sx={{
                  color: "#504E4E",
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  fontWeight: "500",
                  lineHeight: "16px",
                  pr: { xs: 0, md: 12 },
                }}
              >
                Memecoin season is only just starting on Flare and there is a
                huge opportunity for growth in this space. $BLAST combines the
                winning ingredients to be the number 1 on the network. It’s a
                funny dog heading in one direction only, backed by a network of
                influencer, respected community members and tipped for success
                by experts in multiple crypto news outlets. This canine wants to
                dominate the exchanges!
              </Typography>
            </Box>
          </Grid>
          {footerArray.map(({ head, textArray, pdfStatus }, i) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              key={head}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "start", md: "center" },
                flexDirection: "column",
              }}
            >
              <Box pl={{ xs: 0, sm: 3, md: 10 }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontFamily: "Wagoon Bold",
                    fontSize: { xs: "18px", sm: "20px" },
                    fontWeight: { xs: "600", sm: "700" },
                    color: "#504E4E",
                  }}
                >
                  {head}
                </Typography>
                {textArray.map(({ name, link, pdfStatus }) =>
                  pdfStatus ? (
                    <a
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      key={name}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "#504E4E",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "700",
                          my: 2,
                          cursor: "pointer",
                        }}
                      >
                        {name}
                      </Typography>
                    </a>
                  ) : (
                    <HashLink
                      smooth
                      key={name}
                      to={link}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "#504E4E",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "700",
                          my: 2,
                          cursor: "pointer",
                        }}
                      >
                        {name}
                      </Typography>
                    </HashLink>
                  )
                )}
              </Box>
              {footerArray?.length === i + 1 && !matches && (
                <Box
                  display={"flex"}
                  alignItems={{ xs: "center", md: "end" }}
                  justifyContent={{ xs: "center", md: "end" }}
                  spacing={1}
                  mr={3}
                >
                  {socialArray?.map(({ icon, link }) => (
                    <IconButton href={link} target="_blank" key={icon} m={0}>
                      <Box component={"img"} src={icon} alt="" width={"32px"} />
                    </IconButton>
                  ))}
                </Box>
              )}
            </Grid>
          ))}
          {matches && (
            <Grid item xs={12}>
              <Box
                display={"flex"}
                alignItems={{ xs: "center", md: "end" }}
                justifyContent={{ xs: "center", md: "end" }}
                spacing={1}
                mr={3}
              >
                {socialArray?.map(({ icon, link }) => (
                  <IconButton href={link} target="_blank" key={icon} m={0}>
                    <Box component={"img"} src={icon} alt="" width={"32px"} />
                  </IconButton>
                ))}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#ffffff",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "center",
                lineHeight: "16px",
                px: { xs: 0, md: 20 },
              }}
            >
              <span
                style={{
                  fontWeight: "700",
                }}
              >
                Disclaimer:
              </span>{" "}
              Cryptocurrency may be unregulated in your jurisdiction. The value
              of cryptocurrencies may go down as well as up. Profits may be
              subject to capital gains or other taxes applicable in your
              jurisdiction.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;

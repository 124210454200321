import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  HeadingBg,
  bg4,
  grassBg,
  hBg,
  influencerBg,
  tweetImg,
  tweetImgTwo,
  tweetImgThree,
} from "../components/SmallComponents/Images";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { HashLink } from "react-router-hash-link";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const InfluencerSection = () => {
  const mobileMatches = useMediaQuery("(max-width:650px)");

  const tweets = [
    {
      img: tweetImg,
      link: "",
    },
    {
      img: tweetImgTwo,
      link: "",
    },
    {
      img: tweetImgThree,
      link: "",
    },
  ];

  return (
    <Box>
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            mt={5}
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              data-aos={mobileMatches ? "fade-down" : "fade-right"}
              data-aos-delay="30"
              data-aos-duration="2000"
              sx={{
                backgroundImage: `url(${influencerBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                height: {
                  xs: "fit-content",
                  sm: "fit-content",
                  md: "fit-content",
                },
                width: { xs: "100%", md: "80%" },
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  mt={10}
                  sx={{
                    color: "#ffffff",
                    backgroundImage: `url(${hBg})`,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    py: { xs: 2, sm: 4 },
                    px: { xs: 5, md: 6 },
                    textAlign: "center",
                    height: "fit-content",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Wagoon Bold",
                      fontSize: { xs: "24px", md: "48px" },
                      fontWeight: "400",
                      lineHeight: "48px",
                    }}
                  >
                    Influencer Tweet
                  </Typography>
                </Box>
              </Box>
              <Box
                pt={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Swiper
                  slidesPerView={1}
                  spaceBetween={5}
                  loop={true}
                  autoplay={{
                    delay: 250,
                    disableOnInteraction: false,
                  }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  modules={[Navigation]}
                >
                  {tweets?.map(({ img, link }, i) => (
                    <SwiperSlide key={i * Math.random() * i + i}>
                      {" "}
                      <a
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Box
                          sx={{
                            width: "70%",
                            height: {
                              xs: "300px",
                              sm: "450px",
                              md: "500px",
                            },
                            margin: "auto",
                          }}
                        >
                          <Box
                            component="img"
                            src={img}
                            alt=""
                            sx={{
                              width: "100%",
                              height: "100%",
                              cursor: "pointer",
                              borderRadius: "10px",
                            }}
                            // onClick={(e) => OpenTweet(e)}
                          />
                        </Box>
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  pt: 5,
                  pb: 15,
                }}
              >
                <ButtonGroup
                  variant="outlined"
                  sx={{
                    color: "#ffffff",
                    backgroundImage: `url(${HeadingBg})`,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",

                    textAlign: "center",
                    height: "fit-content",
                    width: "fit-content",
                  }}
                >
                  <Button
                    className="swiper-button-prev"
                    disableElevation={true}
                    disableRipple={true}
                    sx={{
                      position: "relative",
                      pl: 3,
                      color: "white",
                      border: "none",
                      width: "20px",
                      fontWeight: "400",
                      "&:hover": {
                        backgroundColor: "none",
                        boxShadow: "none",
                        border: "none",
                      },
                    }}
                  >
                    <ArrowBackIosIcon fontSize="medium" />
                  </Button>
                  <Button
                    className="swiper-button-next"
                    disableElevation={true}
                    disableRipple={true}
                    sx={{
                      position: "relative",
                      color: "white",
                      border: "none",
                      fontWeight: "400",
                      width: "20px",

                      "&:hover": {
                        backgroundColor: "none",
                        boxShadow: "none",
                        border: "none",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon fontSize="medium" />
                  </Button>
                </ButtonGroup>
              </Box>

              <Box
                component="img"
                src={grassBg}
                sx={{
                  height: { xs: 80, md: 100 },
                  width: { xs: 100, md: 140 },
                  position: "absolute",
                  left: { xs: "5%", md: "5%" },
                  bottom: { xs: "7%", md: "7%" },
                }}
              />
              <Box
                component="img"
                src={grassBg}
                sx={{
                  height: { xs: 100, md: 130 },
                  width: { xs: 130, md: 180 },
                  position: "absolute",
                  right: { xs: "-1%", md: "-5%" },
                  bottom: { xs: "7%", md: "7%" },
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            mt={{ xs: 5, md: 0 }}
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              data-aos={mobileMatches ? "fade-up" : "fade-left"}
              data-aos-delay="30"
              data-aos-duration="2000"
              pt={5}
              sx={{
                backgroundPosition: "center",
                alignItems: "center",

                backgroundImage: `url(${bg4})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                height: { xs: "fit-content", md: "500px" },
                width: { xs: "fit-content", md: "470px" },
              }}
            >
              <Box
                px={3}
                pt={{ xs: 15, md: 17 }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ul
                  style={{
                    color: "white",
                    zIndex: "10000",

                    fontSize: mobileMatches ? "14px" : "20px",
                  }}
                >
                  <li>Supported by 10+ network specific influencers</li>
                  <li>
                    Plans for multiple social media approaches that include
                    large Youtube, TikTok and Instagram accounts{" "}
                  </li>
                  <li>
                    One of the biggest crypto gem opportunities right now!{" "}
                  </li>
                  <li>
                    Goal: To have fun and be the number 1 memecoin on Flare
                  </li>
                </ul>
              </Box>
              <Box
                sx={{
                  pb: { xs: 2, md: 5 },
                  textAlign: "center",
                }}
              >
                <HashLink
                  smooth
                  to={"/#home"}
                  style={{ textDecoration: "none" }}
                >
                  <StyledButton width={"180px"}>Buy Now</StyledButton>
                </HashLink>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default InfluencerSection;

import {
  Avatar,
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  hBg,
  presaleBg,
  presalesingleBg,
  smallLogo,
} from "../components/SmallComponents/Images";
import { AppContext } from "../utils";
import {
  preSaleReadFunction,
  tokenReadFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits } from "viem";

const stageDataArray = [
  {
    stepNo: 0,
    tokensToSell: 0,
    soldTokens: 0,
    price: 0,
    progress: 0,
    UsdtHardcap: 0,
    amountRaised: 0,
  },
  {
    stepNo: 1,
    tokensToSell: 0,
    soldTokens: 0,
    price: 0,
    progress: 0,
    UsdtHardcap: 0,
    amountRaised: 0,
  },
  {
    stepNo: 2,
    tokensToSell: 0,
    soldTokens: 0,
    price: 0,
    progress: 0,
    UsdtHardcap: 0,
    amountRaised: 0,
  },
  {
    stepNo: 3,
    tokensToSell: 0,
    soldTokens: 0,
    price: 0,
    progress: 0,
    UsdtHardcap: 0,
    amountRaised: 0,
  },
  {
    stepNo: 4,
    tokensToSell: 0,
    soldTokens: 0,
    price: 0,
    progress: 0,
    UsdtHardcap: 0,
    amountRaised: 0,
  },
];

const PreSale = () => {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const { callFunction, setCallFunction } = useContext(AppContext);
  const [stageData, setStageData] = useState(stageDataArray);
  const [currentLiveStage, setCurrentLiveStage] = useState(0);

  const toLocalFormat = (val) => {
    return val?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    (async () => {
      try {
        const tokenDecimal = await tokenReadFunction("decimals");
        const newData = [];
        for (let i = 0; i < 5; i++) {
          const preSaleStagesConract = await preSaleReadFunction("phases", [
            i?.toString(),
          ]);
          const obj = {
            stepNo: i,
            tokensToSell: +parseFloat(
              formatUnits(
                preSaleStagesConract[0]?.toString(),
                +tokenDecimal?.toString()
              )
            )?.toFixed(0),
            price: +parseFloat(
              formatUnits(
                preSaleStagesConract[2]?.toString(),
                +tokenDecimal?.toString()
              )
            )?.toFixed(3),

            progress:
              +preSaleStagesConract[1]?.toString() > 0
                ? (+formatUnits(
                    preSaleStagesConract[1]?.toString(),
                    +tokenDecimal?.toString()
                  ) /
                    +formatUnits(
                      preSaleStagesConract[0]?.toString(),
                      +tokenDecimal?.toString()
                    )) *
                  100
                : 0,
          };
          newData.push(obj);
        }
        setStageData(newData);
        const currentStageContract = await preSaleReadFunction("currentStage");
        setCurrentLiveStage(+currentStageContract?.toString());
      } catch (e) {
        console.log(e);
      }
    })();
    setCallFunction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction]);

  return (
    <Box
      id="presale-stages"
      mt={6}
      pb={5}
      sx={{
        backgroundColor: "#EE7D08",
        backgroundImage: `url(${presaleBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height: "fit-content",
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            mt={6}
            sx={{
              color: "#ffffff",
              backgroundImage: `url(${hBg})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              py: { xs: 2, sm: 2 },
              px: 5,
              textAlign: "center",
              height: "fit-content",
              width: "fit-content",
            }}
          >
            <Typography
              ml={2}
              variant="h5"
              sx={{
                fontFamily: "Wagoon Bold",
                fontSize: { xs: "24px", md: "48px" },
                fontWeight: "400",
                lineHeight: "48px",
              }}
            >
              Presale schedule
            </Typography>
          </Box>
        </Box>
        {/* <Box px={{ xs: 6, sm: 10 }}>
          <Typography
            mt={3}
            py={2}
            variant="body2"
            sx={{
              px: { xs: 0, md: 5 },
              fontFamily: "Montserrat",
              fontSize: { xs: "12px", md: "15px" },
              fontWeight: "500",
              lineHeight: "15px",
              textAlign: "center",
              color: "#ffffff",
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </Typography>
        </Box> */}
        <Grid container justifyContent="center">
          {stageData.map(({ stepNo, tokensToSell, price, progress }, index) => (
            <Grid
              item
              xs={12}
              md={4}
              key={index}
              sx={{
                mt: {
                  xs: 5,
                  md: index === 4 || index === 3 || index === 5 ? 5 : 5,
                },
              }}
            >
              <Box
                data-aos={
                  index > 2
                    ? mobileMatches
                      ? "fade-down"
                      : "fade-left"
                    : mobileMatches
                    ? "fade-down"
                    : "fade-right"
                }
                data-aos-delay="30"
                data-aos-duration="2000"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    height: "180px",
                    width: { xs: "fit-content", md: "370px" },
                    backgroundImage: `url(${presalesingleBg})`,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Box
                    px={5}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="h5"
                      mt={3}
                      sx={{
                        fontFamily: "Wagoon Bold",
                        fontSize: { xs: "20px", md: "20px" },
                        fontWeight: "400",
                        lineHeight: "12px",
                        color: "#FFFFFF",
                      }}
                    >
                      STAGE {+stepNo + 1}
                    </Typography>
                    <Box
                      className={index === +currentLiveStage ? "glow" : ""}
                      mt={3}
                      sx={{
                        color: "#FFFFFF",
                        backgroundColor:
                          index < +currentLiveStage
                            ? "#F50D0D"
                            : index === +currentLiveStage
                            ? "#21C107"
                            : "#a12c0b",
                        height: "14px",
                        width: "62px",
                        borderRadius: "8px",

                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: { xs: "9px", md: "9px" },
                          fontWeight: "400",
                          lineHeight: "12px",
                        }}
                      >
                        {index < +currentLiveStage
                          ? "Soldout"
                          : index === +currentLiveStage
                          ? "Live"
                          : "Upcoming"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    mt={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Box sx={{ height: 34, width: { xs: 250, md: 270 } }}>
                      <Stack
                        sx={{
                          height: "20px",
                          boxShadow:
                            "5.091511249542236px 10.183022499084473px 15.274534225463867px 0px #00000080",
                          background: "#F3DC09",
                          mt: 0.5,
                          borderRadius: "20px",
                          border: "1px solid #EE7D08",
                          overflow: "hidden",
                          mx: 1,
                        }}
                      >
                        <Stack
                          height={"100%"}
                          alignItems={"start"}
                          justifyContent={"center"}
                          position={"relative"}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              boxShadow:
                                "4.073208808898926px 4.073208808898926px 10.183022499084473px 0px #FFFFFFCC inset,0px 4.493834972381592px 9.986300468444824px 0px #A201274D",
                              left: 0,
                              top: 0,
                              bottom: 0,
                              width: `${progress}%`,
                              borderRadius: "20px",
                              backgroundColor: "#D71C26",
                              zIndex: 0,
                            }}
                          />
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: "#ffff",
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: "600",
                              zIndex: 1,
                              pl: 1.2,
                            }}
                          >
                            {parseFloat(progress)?.toFixed(2)}%
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"flex-end"}
                        my={1}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#ffff",
                            fontFamily: "Montserrat",
                            fontSize: { xs: "11px", sm: "11px" },
                            fontWeight: "400",
                            pr: 1.2,
                          }}
                        >
                          {parseFloat(progress)?.toFixed(1)}% / 100%
                          {/* ${toLocalFormat(amountRaised)} / $
                            {toLocalFormat(UsdtHardcap)} */}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                  <Box ml={4} mt={4} sx={{ display: "flex" }}>
                    <Avatar
                      src={smallLogo}
                      sx={{ height: "30px", width: "30px" }}
                    />

                    <Stack ml={1} direction={"column"} spacing={1}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: { xs: "9px", md: "12px" },
                          fontWeight: "600",
                          lineHeight: "10px",
                          color: "#ffffff",
                        }}
                      >
                        {toLocalFormat(+tokensToSell)}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: { xs: "7px", md: "9px" },
                          fontWeight: "400",
                          lineHeight: "10px",
                          color: "#ffffff",
                        }}
                      >
                        Shiba Blast Allocation for this stage
                      </Typography>
                    </Stack>
                    <Stack direction={"column"} spacing={1} px={3}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: { xs: "9px", md: "12px" },
                          fontWeight: "600",
                          lineHeight: "10px",
                          color: "#ffffff",
                        }}
                      >
                        FLR {parseFloat(1 / price).toFixed(2)}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: { xs: "7px", md: "9px" },
                          fontWeight: "400",
                          lineHeight: "10px",
                          color: "#ffffff",
                        }}
                      >
                        Purchase Price <br /> (1 BLAST )
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default PreSale;

import React from "react";
import {
  Container,
  useMediaQuery,
  SwipeableDrawer,
  Button,
  List,
  ListItemText,
  Box,
  Paper,
  IconButton,
  ListItemButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import {
  logo,
  youtubeIcon,
  twitterIcon,
  tiktokIcon,
  telegramIcon,
  instaIcon,
} from "./SmallComponents/Images";
import { StyledText } from "./SmallComponents/AppComponents";
import { WalletButton } from "./SmallComponents/StyledWalletButton";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const navArray = [
  {
    text: "HOME",
    link: "/#home",
  },
  {
    text: "Presale Stages",
    link: "/#presale-stages",
  },
  {
    text: "Tokenomics",
    link: "/#tokenomics",
  },
  {
    text: "Roadmap",
    link: "/#roadmap",
  },
  {
    text: "FAQ's",
    link: "/#faqs",
  },
];

export const socialArray = [
  {
    icon: telegramIcon,
    link: "https://t.me/+8I9PF_leXqBhYjNl",
  },
  {
    icon: twitterIcon,
    link: "https://twitter.com/Shiba_Blast",
  },
];

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background:
      "linear-gradient(180deg, #FED319 -26.27%, #EE7D08 133.33%) !important",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

export default function Header() {
  const matches = useMediaQuery("(max-width:1050px)");
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const matches1 = useMediaQuery("(max-width:1279px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box display="flex" justifyContent="center" my={2}>
        <Link
          to={"/"}
          style={{ textDecoration: "none" }}
          onClick={toggleDrawer(anchor, false)}
        >
          <Box component={"img"} width="130px" src={logo} alt="" />
        </Link>
      </Box>
      <List>
        {navArray?.map(({ text, link }) => (
          <ListItemButton key={text}>
            <HashLink
              smooth
              to={link}
              style={{ textDecoration: "none", margin: "auto" }}
            >
              <ListItemText
                sx={{
                  textTransform: "capitalize",
                  textAlign: "center",
                  textDecoration: "none",
                  cursor: "pointer",

                  color: "#ffffff",
                  ".MuiTypography-root": {
                    fontFamily: "Wagoon Bold",
                    fontSize: "20px",
                  },
                }}
                primary={text}
              />
            </HashLink>
          </ListItemButton>
        ))}
      </List>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={1}
        my={2}
      >
        {socialArray?.map(({ icon, link }) => (
          <IconButton href={link} target="_blank" key={icon}>
            <Box component={"img"} src={icon} alt="" width={"30px"} />
          </IconButton>
        ))}
      </Box>
      <Box mb={1} display="flex" justifyContent="center">
        <WalletButton width={"220px"} />
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          background: "transparent",
        }}
        width="100%"
        pb={1}
        pt={2}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              component={"img"}
              width={{ xs: "80px", md: "100px" }}
              src={logo}
              alt=""
            />
            <Box
              display="flex"
              justifyContent={matches1 ? "end" : "space-between"}
              alignItems="center"
            >
              {!matches ? (
                <>
                  {navArray?.map(({ text, link }) => (
                    <HashLink
                      key={text}
                      smooth
                      to={link}
                      style={{ textDecoration: "none" }}
                    >
                      <StyledText
                        key={text}
                        mr={{ xs: 4, md: 3, xl: 4, lg: 4 }}
                      >
                        {text}
                      </StyledText>
                    </HashLink>
                  ))}

                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    spacing={1}
                    mr={3}
                  >
                    {socialArray?.map(({ icon, link }) => (
                      <IconButton href={link} target="_blank" key={icon} m={0}>
                        <Box
                          component={"img"}
                          src={icon}
                          alt=""
                          width={"32px"}
                        />
                      </IconButton>
                    ))}
                  </Box>
                  <WalletButton width={"220px"} />
                </>
              ) : (
                <>
                  {["left"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        onClick={toggleDrawer(anchor, true)}
                        style={{ zIndex: 1 }}
                      >
                        <MenuIcon
                          style={{
                            fontSize: "38px",
                            cursor: "pointer",
                            color: "#ffffff",
                          }}
                        />
                      </Button>
                      <Paper>
                        <SwipeableDrawer
                          classes={{ paper: classes.paper }}
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                        >
                          {list(anchor)}
                        </SwipeableDrawer>
                      </Paper>
                    </React.Fragment>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
